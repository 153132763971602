var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehouse" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.warehouseList, function(itemslist, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "left",
                          staticStyle: { background: "#FF2D2E" }
                        },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v("快递入库")
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "orderInfo" }, [
                          _c("div", { staticClass: "flex_between" }, [
                            _c(
                              "h1",
                              {
                                staticClass: "comeWight",
                                staticStyle: {
                                  width: "220px",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [_vm._v(" " + _vm._s(itemslist.expressNo))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "status",
                                class: {
                                  greedBorder:
                                    itemslist.referenceStatus == "REFERENCED"
                                      ? true
                                      : false,
                                  redBorder:
                                    itemslist.referenceStatus == "NONE"
                                      ? true
                                      : false
                                },
                                style: {
                                  color:
                                    itemslist.referenceStatus == "NONE"
                                      ? "#ff2d2e"
                                      : itemslist.referenceStatus ==
                                        "REFERENCED"
                                      ? "#02790D"
                                      : "#999999"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      itemslist.referenceStatus == "NONE"
                                        ? "待处理"
                                        : "已处理"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "newWight" }, [
                            _vm._v("报单重量 " + _vm._s(itemslist.weight) + "g")
                          ])
                        ]),
                        _c("div", { staticClass: "orderNmber" }, [
                          _c("div", [
                            _c("div", { staticClass: "time" }, [
                              _vm._v(
                                " 入库时间: " +
                                  _vm._s(
                                    _vm._f("formatDate_")(itemslist.signedTime)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "incoming" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.incomingList, function(itemslist, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.hrefdetails(itemslist.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "div",
                          {
                            staticClass: "left",
                            staticStyle: { background: "#FF2D2E" }
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("来料入库")
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "orderInfo" }, [
                            _c("div", { staticClass: "flex_between" }, [
                              _c(
                                "h1",
                                {
                                  staticClass: "comeWight",
                                  staticStyle: {
                                    width: "220px",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis"
                                  }
                                },
                                [
                                  _vm._v(
                                    "来料类别 " +
                                      _vm._s(itemslist.goodsNames.join(","))
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "status",
                                  class: {
                                    greedBorder:
                                      itemslist.referenceStatus ==
                                        "REFERENCED" ||
                                      itemslist.referenceStatus ==
                                        "CONFIRMED" ||
                                      itemslist.referenceStatus == "TICHUN"
                                        ? true
                                        : false,
                                    redBorder:
                                      itemslist.referenceStatus == "NONE" ||
                                      itemslist.referenceStatus ==
                                        "WAIT_CONFIRM" ||
                                      itemslist.referenceStatus == "TICHUNING"
                                        ? true
                                        : false,
                                    ashBorder:
                                      itemslist.referenceStatus == "REJECT"
                                        ? true
                                        : false
                                  },
                                  style: {
                                    color:
                                      itemslist.referenceStatus == "NONE" ||
                                      itemslist.referenceStatus ==
                                        "WAIT_CONFIRM" ||
                                      itemslist.referenceStatus == "TICHUNING"
                                        ? "#ff2d2e"
                                        : itemslist.referenceStatus ==
                                            "REFERENCED" ||
                                          itemslist.referenceStatus ==
                                            "CONFIRMED" ||
                                          itemslist.referenceStatus == "TICHUN"
                                        ? "#02790D"
                                        : "#999999"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        itemslist.referenceStatus == "REJECT"
                                          ? "待审核"
                                          : itemslist.referenceStatus ==
                                            "REFERENCED"
                                          ? "已处理"
                                          : itemslist.referenceStatus ==
                                            "CONFIRMED"
                                          ? "已确认"
                                          : itemslist.referenceStatus ==
                                            "TICHUN"
                                          ? "提纯完成"
                                          : itemslist.referenceStatus ==
                                            "TICHUNING"
                                          ? "提纯中"
                                          : "待确认"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "newWight" }, [
                              _vm._v(
                                "来料重量 " +
                                  _vm._s(itemslist.totalWeight) +
                                  "g"
                              )
                            ]),
                            _c("div", { staticClass: "newWight" }, [
                              _vm._v(
                                "折足重量 " +
                                  _vm._s(itemslist.totalDiscountWeight) +
                                  "g"
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "orderNmber" }, [
                            _c("div", [
                              _c("div", { staticClass: "time" }, [
                                _vm._v(
                                  " 检测时间: " +
                                    _vm._s(
                                      _vm._f("formatDate_")(itemslist.stockTime)
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
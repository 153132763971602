<template>
  <div class="incomingManage">
    <van-tabs v-model="active" animated sticky type="card">
      <van-tab title="快递入库" name="warehouse">
        <keep-alive>
          <warehouse />
        </keep-alive>
      </van-tab>
      <van-tab title="来料入库" name="incoming">
        <keep-alive>
          <incoming />
        </keep-alive>
      </van-tab>
    </van-tabs>
    <!-- 右侧统计 -->
    <div class="tabsRight" >
      <span @click="rightClick">来料统计</span>
      <van-icon name="arrow" />
    </div>
    <div class="tabsLeftIcon" @click="back">
      <van-icon name="arrow-left" />
    </div>
  </div>
</template>

<script>
import warehouse from "./warehouse/index.vue";
import incoming from "./incoming/index.vue";
export default {
  components: {
    warehouse,
    incoming
  },
  data() {
    return {
      active: "warehouse"
    };
  },
  mounted() {
    this.active = this.$route.query.active ? this.$route.query.active : "warehouse";
  },
  methods: {
    back() {
      this.$router.push({
        name: "person"
      });
    },
    // 顶部右侧事件
    rightClick(){
      this.$router.push({
        name: 'incomingStatistics',
      })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep{

  .van-tabs__wrap{
    height: 88px;
    padding: 0 220px;
    background-color: #fff;
  }
  .van-tabs__line{
    display: none;
  }
  .van-tabs__nav--line {
    padding-bottom: 0 !important;
  }
  .van-tabs__nav{
    height: 50px;
    margin-top: 16px;
    border-radius: 10px ;
  }
  .van-tab{
    height: 100%;
    line-height: 50px;
    font-size: 28px;
    padding: 0;
  }
}
.incomingManage{
  background-color: #f5f5f5;
}
.tabsLeftIcon{
  position: fixed;
  top: 30px;
  left: 10px;
  z-index: 9999;
  .van-icon{
    font-size: 32px;
  }
}
.tabsRight {
  position: fixed;
  z-index: 9999;
  top: 18px;
  right: 30px;
  height: 50px;
  font-size: 28px;
  line-height: 50px;
  .van-icon{
    font-size: 26px;
  }
}
</style>

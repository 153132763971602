<template>
  <div class="warehouse">
    <van-pull-refresh
      v-model="refreshing"
      success-text="刷新成功"
      @refresh="onRefresh"
      style="min-height: 100vh;"
    >
      <template v-if="ishow">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="!ishow"
      >
        <div
          v-for="(itemslist, index) in warehouseList"
          :key="index"
        >
          <div class="flex">
            <div class="left" style="background:#FF2D2E">
              <div class="text">快递入库</div>
            </div>
            <div class="right">
              <div class="orderInfo">
                <div class="flex_between">
                  <h1 class="comeWight" style="width: 220px; overflow:hidden;white-space:nowrap;text-overflow:ellipsis">
                    {{ itemslist.expressNo }}</h1>
                  <div class="status" :class="{
                        greedBorder: itemslist.referenceStatus == 'REFERENCED'?true:false,
                        redBorder:itemslist.referenceStatus == 'NONE'?true:false,
                        }"
                       :style="{color:itemslist.referenceStatus == 'NONE'?'#ff2d2e'
                        :itemslist.referenceStatus == 'REFERENCED'?'#02790D' : '#999999'}"
                  >
                    {{
                      itemslist.referenceStatus == "NONE"
                        ? "待处理"
                        : "已处理"
                    }}
                  </div>
                  <!-- <div class="status">{{text}}</div> -->
                </div>
                <div class="newWight">报单重量 {{ itemslist.weight }}g</div>
              </div>
              <div class="orderNmber">
                <div>
                  <div class="time">
                    入库时间: {{ itemslist.signedTime | formatDate_ }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { warehouse } from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
export default {
  props: ['activeName'],
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      page: 0,
      size: 8,
      ishow: true, // 是否有数据
      warehouseList: [] // 快递入库数据
    };
  },
  filters: {
    formatDate_
  },
  created(){
    this.onRefresh()
  },
  methods: {
    async onLoad(){
      // console.log('page2',this.page);
      let res = await warehouse({page:this.page,size:this.size})
      this.page = res.number + 1

      this.warehouseList.push(...res.content)
      this.loading = false
      if (this.warehouseList.length != 0) {
        this.ishow = false;
      } else {
        this.ishow = true;
      }
      if (res.last == true) {
        this.finished = true
      }

    },
    // 下拉事件
    async onRefresh() {
      this.finished = false
      try {
        const res = await warehouse({page:0,size:this.size});
        this.warehouseList = res.content;
        this.page = res.number + 1
        if (this.warehouseList.length != 0) {
          this.ishow = false;
        } else {
          this.ishow = true;
        }
        if (res.last == true) {
          this.finished = true
        }
        this.loading = false
        console.log("this.incomingList", this.warehouseList);
        // this.$Loading.close();
      } catch (err) {
        this.ishow = true;
      }
      setTimeout(() => {
        this.refreshing = false
      }, 500);
    },
  }
};
</script>

<style lang="scss" scoped="true">
.incoming {
  background-color: #f5f5f5;
}
#loadingbox {
  margin-top: 150px;
}
::v-deep {
  .van-loading__text {
    font-size: 30px;
  }
  .van-list__finished-text {
    font-size: 30px;
  }
  .van-pull-refresh__head {
    font-size: 30px !important;
  }
  .van-empty__image {
    width: 420px;
    height: 260px;
  }
}
.van-empty {
  margin-top: 150px;
}
::v-deep {
  .van-pull-refresh {
    padding: 30px 40px 40px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  .left {
    width: 40px;
    height: 170px;
    background-color: #cc0e0f;
    padding: 28px 0;
    border-radius: 8px 0px 0px 8px;
    .text {
      width: 24px;
      height: 80px;
      font-size: 24px;
      color: #ffffff;
      line-height: 30px;
      margin: auto;
    }
  }
  .right {
    width: 100%;
    background-color: #fff;
    height: 170px;
    padding: 0 20px;
    .orderInfo {
      padding: 10px 0;
      border-bottom: 1px solid #e9e9e9;
      .comeWight {
        color: #242424;
        font-size: 32px;
        font-weight: 500;
        line-height: 42px;
      }
      .newWight {
        margin-top: 10px;
        color: #999999;
        font-size: 24px;
        line-height: 30px;
      }
      .status {
        width: 120px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background-color: #fff;
        border: 1px solid #ff2d2e;
        border-radius: 50px;
        font-size: 28px;
        color: #ff2d2e;
      }
      .redBorder{
        border: 1px solid #FF2D2E;
      }
      .greedBorder{
        border: 1px solid #02790D !important;

      }
      .ashBorder{
        border: 1px solid #999999 !important;

      }
      .BrokenWeight {
        color: #999999;
        font-size: 28px;
        line-height: 34px;
      }
    }
    .orderNmber {
      padding: 10px 0;
      color: #999999;
      font-size: 24px;
    }
  }
}
</style>
